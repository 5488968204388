import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import { LoadingIndicator } from './components/LoadingIndicator/LoadingIndicator';
import { NotificationsContainer } from './components/Notifications/Notifications';
import { BRAND } from './models/Article';
import ArticleDetails from './pages/ArticleDetails/ArticleDetails';
import ArticleLandingPage from './pages/ArticleLandingPage/ArticleLandingPage';
import BrandChooser from './pages/BrandChooser/BrandChooser';
import Cpanel from './pages/Cpanel/Cpanel';
import Dictionary from './pages/Dictionary/Dictionary';
import InfoWithCallToActionPage from './pages/InfoWithCallToActionPage/InfoWithCallToActionPage';
import Login from './pages/Login/Login';
import LoginSuccess from './pages/Login/LoginSuccess';
import Logout from './pages/Login/Logout';
import NotFound from './pages/NotFound/NotFound';
import { useAuthContext } from './services/Auth/AuthContext';
import AuthProvider, { validBrands } from './services/Auth/AuthProvider';
import './styles/main.scss';
import { TtsStyle as ttsStyle } from './styles/theme';

export const AuthenticationAwareAppPart = () => {
  const { userAuthenticated: authenticated, loading, brand } = useAuthContext();

  return (
    <Router>
      <ThemeProvider theme={ttsStyle}>
        <Layout>
          <NotificationsContainer />
          <LoadingIndicator />
          {!loading && authenticated && brand && (
            <Switch>
              <Route exact path="/" component={BrandChooser} />
              <Route exact path="/dictionary" component={Dictionary} />
              <Route path="/not-found" component={NotFound} />
              <Route
                exact
                path="/:brand/cpanel"
                render={(props) => {
                  return validBrands.includes(props.match.params.brand as BRAND) ? (
                    <Cpanel />
                  ) : (
                    <Redirect to="/not-found" />
                  );
                }}
              />
              <Route
                exact
                path="/:brand"
                render={(props) => {
                  return validBrands.includes(props.match.params.brand as BRAND) ? (
                    <ArticleLandingPage />
                  ) : (
                    <Redirect to="/not-found" />
                  );
                }}
              />
              <Route
                exact
                path="/:brand/:id"
                render={(props) => {
                  const pathBrand: BRAND = props.match.params.brand as BRAND;
                  const idBrand: string = props.match.params.id as string;

                  return validBrands.includes(pathBrand) && idBrand.startsWith(pathBrand) ? (
                    <ArticleDetails {...props} />
                  ) : (
                    <Redirect to="/not-found" />
                  );
                }}
              />
            </Switch>
          )}

          {!loading && !authenticated && (
            <Switch>
              <Route exact path={'/login-success'} component={LoginSuccess} />
              <Route exact path={'/logout'} component={Logout} />
              <Route component={Login} />
            </Switch>
          )}

          {loading && (
            <Switch>
              <Route component={InfoWithCallToActionPage} />
            </Switch>
          )}

          {!loading && authenticated && !brand && (
            <Switch>
              <Route path={'*'} component={BrandChooser} />
            </Switch>
          )}
        </Layout>
      </ThemeProvider>
    </Router>
  );
};

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    // StylesProvider helps to re-order the css applied to components
    // `injectFirst` will add the component styles after material-ui styles
    // Thus we won't need to add `!important` when overriding material-ui styles
    <StyledEngineProvider injectFirst>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <AuthenticationAwareAppPart />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthProvider>
    </StyledEngineProvider>
  );
};

export default App;
