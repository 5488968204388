import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { RouteComponentProps } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { withInitializedUser } from '../../components/InitializedUser/WithInitializedUser';
import { NotificationService } from '../../components/Notifications/Notifications';
import { SSMLTextEditor } from '../../components/SSMLTextEditor/SSMLTextEditor';
import { ArticleStatus, IArticle, IPublication, SynthesisProgress } from '../../models/Article';
import * as ArticleService from '../../services/ArticleService';
import { UpdateArticleParams } from '../../services/ArticleService';
import { ArticleAudioPreview } from './ArticleAudioPreview/ArticleAudioPreview';
import './ArticleDetails.scss';
import ArticleDetailsSkeleton from './ArticleDetailsSkeleton/ArticleDetailsSkeleton';
import ArticleFooter from './ArticleFooter/ArticleFooter';
import { ArticleHeader } from './ArticleHeader/ArticleHeader';
import DictionaryBucket from './DictionaryBucket/DictionaryBucket';
import { withDictionaryContext } from './DictionaryBucket/store/DictionaryContext';

const UPDATE_INTERVAL = 5000;

export type ArticleDetailsProps = {
  id: string;
};
export const ArticleDetails = ({ match }: RouteComponentProps<ArticleDetailsProps>) => {
  const articleId = match.params.id;

  const [article, setArticle] = useState<IArticle | null>(null);
  const [currentAudioProgress, setCurrentAudioProgress] = useState(0);
  // Keep track of `Text` field changes
  const [textFormState, setTextFormState] = useState('');
  // Keep track of `Title` field changes
  const [titleFormState, setTitleFormState] = useState('');
  // Keep track of `readonly` mode changes for Editor
  const [readonly, setReadonly] = useState(true);
  const [pollingForStatusUpdate, setPollingForStatusUpdate] = useState(false);
  const articleDetailsBodyRef = useRef<HTMLDivElement>(null);
  const articleDetailsBodyActionsRef = useRef<HTMLDivElement>(null);

  /**
   * @description When an Article is in `Synthesis === 'running'` or `Publication === 'running'` state
   * We enable the `polling` which checks for update in 5 seconds interval
   * */
  useEffect(() => {
    if (!article) return;

    const shouldPollForUpdates =
      article.status === ArticleStatus.RUNNING || article.status === ArticleStatus.PUBLISHING;

    if (shouldPollForUpdates) {
      setReadonly(true);
    }
    setPollingForStatusUpdate(shouldPollForUpdates);
  }, [article]);

  useQuery({
    queryKey: ['fetchArticleJobById', articleId, pollingForStatusUpdate],

    queryFn: async () => {
      // polling for updates in the background
      if (pollingForStatusUpdate) {
        return ArticleService.getArticleById(articleId)
          .then((article) => {
            setArticle(article);
            return article;
          })
          .catch((error) => {
            NotificationService.error(error);
            return error;
          });
      }
      // initial call; blocking the user by displaying a loading screen
      return fetchArticle();
    },

    refetchInterval: pollingForStatusUpdate ? UPDATE_INTERVAL : false,
    retry: 0,
  });

  const fetchArticle = () => {
    const promise = ArticleService.getArticleById(articleId)
      .then((article) => {
        setTextFormState(article.text);
        setTitleFormState(article.title);
        setArticle(article);
        return article;
      })
      .catch((error) => {
        NotificationService.error(error);
        return error;
      });
    return trackPromise(promise);
  };

  const handleTitleChange = (value: string) => {
    setTitleFormState(value);
  };

  const handleTextChange = (value: string) => {
    setTextFormState(value);
  };

  const currentAudioPositionInText = useMemo(() => {
    if (
      !article ||
      !article.synthesis.sentencesStartTimeInSec ||
      article.synthesis.sentencesStartTimeInSec.length === 0
    )
      return;

    const sentencesStartTimeInSec: number[] = [...article.synthesis.sentencesStartTimeInSec];
    const lineBreaksInTitle = article?.title ? article.title.split('\n').length : 0;

    // Remove the entries from the startTimeInSec list that refer to article title
    const articleTextStartTimesInSec = sentencesStartTimeInSec.slice(lineBreaksInTitle);

    return articleTextStartTimesInSec.findIndex((startTimeInSec, index) => {
      const nextItem = articleTextStartTimesInSec[index + 1];
      if (currentAudioProgress >= startTimeInSec && nextItem === undefined) {
        return true;
      }
      return currentAudioProgress >= startTimeInSec && currentAudioProgress < nextItem;
    });
  }, [currentAudioProgress, article?.title, article?.synthesis.sentencesStartTimeInSec]);

  const handleUpdate = (autoPublish = false) => {
    const articleData = {
      text: textFormState,
      title: titleFormState,
      articleSourceVersion: article?.articleSourceVersion as number,
    };

    autoPublish ? synthAndPublish(articleData) : updateArticle(articleData);
  };

  const handlePublish = () => {
    publishArticle();
  };

  const synthAndPublish = (payload: UpdateArticleParams) => {
    const promise = ArticleService.updateArticle
      .synthAndPublish(article?.id as string, payload)
      .then((res) => {
        setArticle(res);
        setPollingForStatusUpdate(true);
        NotificationService.success('Success! Article will be automatically published after synthesis is completed');
      })
      .catch((error: string) => {
        NotificationService.error(error);
      });

    return trackPromise(promise);
  };

  const autoPublishChecked = () => {
    const promise = ArticleService.updateArticle
      .autoPublish(article?.id as string)
      .then((res) => {
        setArticle(res);
        setPollingForStatusUpdate(true);
        NotificationService.success('Success! Article status will be updated');
      })
      .catch((error: string) => {
        NotificationService.error(error);
      });

    return trackPromise(promise);
  };

  const handleReadonlyToggle = () => {
    if (!article) return;

    if (readonly) {
      setReadonly(false);
    } else {
      setTitleFormState(article.title);
      setTextFormState(article.text);
      setReadonly(true);
    }
  };

  const updateArticle = (payload: UpdateArticleParams) => {
    const promise = ArticleService.updateArticle
      .update(article?.id as string, payload)
      .then((res) => {
        setArticle(res);
        NotificationService.success(`Successfully submitted article job with id ${res.id}`);
      })
      .catch((error: string) => {
        NotificationService.error(error);
      });

    return trackPromise(promise);
  };

  const publishArticle = () => {
    const promise = ArticleService.publishArticle(article?.id as string, article?.synthesis.ordinal as number)
      .then(() => {
        setPollingForStatusUpdate(true);
        NotificationService.success(`Successfully started publishing article job with id ${article?.id}`);
      })
      .catch((error) => {
        NotificationService.error(error);
      });

    return trackPromise(promise);
  };

  const editArticleBtnScrollingObserver = () => {
    if (articleDetailsBodyActionsRef?.current && articleDetailsBodyRef?.current) {
      articleDetailsBodyActionsRef.current.style.top = `${articleDetailsBodyRef.current.offsetTop - 0.5 || 0}px`;
    }
  };

  const publicationPossible = useMemo(() => {
    if (!article || !article.synthesis?.finishedAt) {
      return false;
    }
    const isSynthSuccess = article.synthesis.progress === SynthesisProgress.SUCCESS;
    const isSynthNewerThanPublish = article.publishedAt && article.synthesis.finishedAt > article.publishedAt;
    const isPublicationSuccess = article.publications.every(
      (publication: IPublication) => publication.progress === 'SUCCESS'
    );
    const isPublicationNotRunning = article.publications.every((publication) => publication.progress !== 'RUNNING');
    const isPublicationFailure = article.publications.some((publication) => publication.progress === 'FAILURE');

    return (
      (isSynthSuccess && isPublicationSuccess && isSynthNewerThanPublish) ||
      (isSynthSuccess && isPublicationNotRunning) ||
      (!isSynthSuccess && isPublicationFailure)
    );
  }, [article]);

  useLayoutEffect(() => {
    const scrollEventListener = () => {
      editArticleBtnScrollingObserver();
    };
    window.addEventListener('scroll', scrollEventListener);

    return () => window.removeEventListener('scroll', scrollEventListener);
  }, [article?.title]);

  /**
   * Switching the text editor mode (readonly <-||-> edit)
   * changes the height of the article header section
   * the sticky position of the Edit button needs to be adjusted
   * when the Editor mode changes
   */
  useEffect(() => {
    // Timeout is needed since the change of the article header height takes a little time
    const timeout = setTimeout(() => editArticleBtnScrollingObserver());

    return () => clearTimeout(timeout);
  }, [readonly]);

  return (
    <>
      <div className="articleDetails-wrapper">
        <article className="articleDetails" data-testid="articleDetails-content-wrapper">
          {!article ? (
            <ArticleDetailsSkeleton />
          ) : (
            <>
              <div className="articleDetails-audio-player-container">
                <ArticleAudioPreview
                  audioLink={article.downloadLink}
                  brand={article.brand}
                  onAudioProgress={(event) => setCurrentAudioProgress(event)}
                />
              </div>

              <div className="articleDetails-header-container">
                <ArticleHeader readonly={readonly} onTitleChange={handleTitleChange} article={article} />
              </div>

              <section
                ref={articleDetailsBodyRef}
                className={`articleDetails--body${readonly ? '' : ' edit-mode'}`}
                data-testid="articleDetails-body"
              >
                <div
                  ref={articleDetailsBodyActionsRef}
                  className="articleDetails--body__actions-wrapper"
                  data-testid="articleDetails-body-actions-wrapper"
                >
                  <div className="articleDetails--body__actions">
                    <Button
                      variant="text"
                      color="primary"
                      data-testid="articleDetails--toggleEditButton"
                      disabled={pollingForStatusUpdate}
                      onClick={handleReadonlyToggle}
                      startIcon={readonly ? <EditIcon /> : <CloseIcon />}
                      disableElevation
                      disableRipple
                      disableFocusRipple
                    >
                      {readonly ? 'Editieren' : 'Verlassen'}
                    </Button>
                  </div>
                </div>

                <div className="articleJobDetails-text-wrapper">
                  {article.text && (
                    <SSMLTextEditor
                      readonly={readonly}
                      text={article.text}
                      onTextChange={handleTextChange}
                      className="articleJobDetails-text"
                      dataTestId="articleDetails--text"
                      currentAudioPosition={currentAudioPositionInText}
                    />
                  )}
                </div>
              </section>

              <ArticleFooter
                status={article.status}
                autoPublishChecked={autoPublishChecked}
                handleUpdate={handleUpdate}
                pollingForStatusUpdate={pollingForStatusUpdate}
                publicationPossible={publicationPossible}
                handlePublish={handlePublish}
                readonly={readonly}
              />
            </>
          )}
        </article>

        <Footer />
      </div>
      <DictionaryBucket />
    </>
  );
};

export default withInitializedUser(withDictionaryContext(ArticleDetails as FunctionComponent));
