import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ArticleStatusBadge from '../../../components/ArticleStatusBadge/ArticleStatusBadge';
import { ConfirmationDialog } from '../../../components/ConfirmDialog/ConfirmationDialog';
import { StrippedSSMLText } from '../../../components/StrippedSSMLText/StrippedSSMLText';
import { ArticleStatus } from '../../../models/Article';
import { deleteArticle, depublishArticle } from '../../../services/ArticleService';
import { ArticleActionDialog, SELECT_ACTIONS } from '../ArticleActionDialog/ArticleActionDialog';
import ArticlesFilterHeader, { ColumnName } from '../ArticlesFilterHeader/ArticlesFilterHeader';
import { useArticlesContext } from '../store/ArticlesContext';
import './ArticleJobList.scss';

export const humanReadableDate = (date: number) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('de-DE').replace(/\./g, '/');
};

export const humanReadableTime = (date: number) => {
  if (!date) return '';
  const dateObj = new Date(date);
  return `${dateObj.toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Berlin',
  })}`;
};

export const sanitizeArticleCategories = (categories: Array<string>) => {
  const categoryWithoutTrailingSlash = categories.join().replace(/^\/|\/$/g, '');

  if (categoryWithoutTrailingSlash.split('/').length > 2) {
    const splitCategory = categoryWithoutTrailingSlash.split('/');
    return splitCategory
      .reduce((acc: string[], cur, idx) => {
        if (idx === 0 || idx === splitCategory.length - 1) {
          return [...acc, cur];
        }

        return [...acc, '...'];
      }, [])
      .join('/');
  }

  return categoryWithoutTrailingSlash;
};

export type ActionDialogDataType = {
  loadingDialogIsOpen: boolean;
  successfully: number;
  failed: number;
  total: number;
  isRunning: boolean;
};

const CONFIRM_ACTION_DIALOG_TEXT = {
  [SELECT_ACTIONS.delete]: {
    title: 'Löschen',
    text: (articleCount: number) => `Wollen Sie ${articleCount} Artikel wirklich löschen?`,
    actionLabel: 'Löschen',
  },
  [SELECT_ACTIONS.depublish]: {
    title: 'Unveröffentlicht',
    text: (articleCount: number) => `Wollen Sie ${articleCount} Artikel wirklich unveröffentlichen?`,
    actionLabel: 'Unveröffentlichen',
  },
};

type ArticleJobListProps = {
  hasMore: boolean;
  onLoadMore: () => void;
  onListChanged: () => void;
  stopPollingForUpdate: () => void;
  scrollTop: () => void;
  fetchingArticles: boolean;
  hasNoArticles?: boolean;
};

const ArticleJobList = ({
  hasMore,
  onLoadMore,
  onListChanged,
  stopPollingForUpdate,
  scrollTop,
  fetchingArticles,
  hasNoArticles,
}: ArticleJobListProps) => {
  const {
    articles,
    checkedArticleIds,
    setCheckedArticlesIds,
    selectedAction,
    showConfirmActionDialog,
    toggleConfirmActionDialog,
    loadingMore,
  } = useArticlesContext();

  const [actionDialogData, setActionDialogData] = useState<ActionDialogDataType>({
    loadingDialogIsOpen: false,
    successfully: 0,
    failed: 0,
    total: 0,
    isRunning: false,
  });

  const onArticleChecked = (checked: boolean, articleId: string) => {
    if (checked) {
      setCheckedArticlesIds([...checkedArticleIds, articleId]);
    } else {
      const filteredArticleIds = checkedArticleIds.filter((id) => id !== articleId);
      setCheckedArticlesIds(filteredArticleIds);
    }
  };

  const onConfirmActionApply = () => {
    toggleConfirmActionDialog(false);
    onSelectedArticlesAction(selectedAction as string);
  };

  const onDenyActionApply = () => {
    toggleConfirmActionDialog(false);
  };

  const onSelectedArticlesAction = (selectedAction: string) => {
    stopPollingForUpdate();

    setActionDialogData((prevState) => ({
      ...prevState,
      loadingDialogIsOpen: true,
      isRunning: true,
      total: checkedArticleIds.length,
    }));

    const promises: Promise<void>[] = [];

    const doSelectedAction = (articleId: string) => {
      if (selectedAction === SELECT_ACTIONS.delete) {
        return deleteArticle(articleId);
      } else if (selectedAction === SELECT_ACTIONS.depublish) {
        return depublishArticle(articleId);
      }

      return Promise.resolve();
    };

    checkedArticleIds.forEach((articleId) => {
      const actionPromise = doSelectedAction(articleId)
        .then(() => {
          setActionDialogData((prevState) => ({
            ...prevState,
            successfully: prevState.successfully + 1,
          }));
        })
        .catch(() => {
          setActionDialogData((prevState) => ({
            ...prevState,
            failed: prevState.failed + 1,
          }));
        });

      promises.push(actionPromise);

      Promise.all(promises).then(() => {
        setActionDialogData((prevState) => ({
          ...prevState,
          isRunning: false,
        }));
      });
    });
  };

  const handleActionFinishedConfirm = () => {
    setActionDialogData((prevState) => ({
      ...prevState,
      loadingDialogIsOpen: false,
      successfully: 0,
      total: 0,
      failed: 0,
    }));

    uncheckAllArticles();
    onListChanged();
  };

  const checkUncheckAll = () => {
    if (checkedArticleIds.length === articles.length) {
      uncheckAllArticles();
    } else {
      const articleIds = articles.map((article) => article.id);
      setCheckedArticlesIds(articleIds as string[]);
    }
  };

  const uncheckAllArticles = () => {
    setCheckedArticlesIds([]);
  };

  return (
    <article className="articleJobList" data-testid="articleJobList">
      <section className="article-list-table">
        <div className="articleGrid articleGrid__header">
          <ArticlesFilterHeader columnName={ColumnName.STATUS} />
          <div className="text-center">Titel</div>
          <ArticlesFilterHeader columnName={ColumnName.RESSORTS} />
          <div className="text-center">Erstellt</div>
          <div className="text-center pl-0 pr-0">
            <Checkbox
              data-testid="checkbox-all-articles"
              name="checkbox"
              checked={checkedArticleIds.length > 0 && checkedArticleIds.length === articles.length}
              indeterminate={checkedArticleIds.length > 0 && checkedArticleIds.length < articles.length}
              onChange={checkUncheckAll}
            />
          </div>
        </div>
        {hasNoArticles ? (
          <Typography
            data-testid="noArticleMsg"
            align="center"
            variant="h6"
            style={{ padding: '25px 0' }}
            color="error"
          >
            Kein Artikel gefunden!
          </Typography>
        ) : (
          <>
            {!fetchingArticles &&
              articles.map((article, index) => {
                return (
                  <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1, transition: { duration: 0.3 } }}
                    exit={{ y: 100, opacity: 0 }}
                    className="articleGrid"
                    key={index + '_' + article.externalId}
                    data-testid="articleJobList-tr"
                  >
                    <div className="articleGrid__cell">
                      <ArticleStatusBadge
                        status={article.status || ArticleStatus.UNKNOWN}
                        publications={article.publications}
                        dataTestId={'article-job-status-' + article.externalId}
                        isPremium={article.isPremium}
                      />
                    </div>
                    <div className="articleGrid__cell" data-testid="article-job-title">
                      <Link
                        className="articleJobList__link"
                        data-testid="articleJobList-link"
                        to={{
                          pathname: `/${article.brand}/${article.id}`,
                          state: article,
                        }}
                      >
                        <StrippedSSMLText>{article.title || '(Titel fehlt)'}</StrippedSSMLText>
                      </Link>
                    </div>
                    <div className="articleGrid__cell categories" data-testid="article-job-categories">
                      {sanitizeArticleCategories(article.categories)}
                    </div>

                    <div className="articleGrid__cell" data-testid="article-job-createdAt">
                      {humanReadableTime(article.createdAt)} Uhr,
                      <br />
                      {humanReadableDate(article.createdAt)}
                    </div>

                    <div className="articleJobList-item--checkbox articleGrid__cell">
                      <Checkbox
                        data-testid={'checkbox-' + article.id}
                        name="checkbox"
                        checked={checkedArticleIds.includes(article.id as string)}
                        onChange={(evt) => {
                          onArticleChecked(evt.target.checked, article.id as string);
                        }}
                        inputProps={{
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          'data-testid': `inputCheckbox-${article.id}`,
                        }}
                      />
                    </div>
                  </motion.div>
                );
              })}
          </>
        )}
      </section>
      <footer>
        <Button className="go-top-btn" data-testid="gotToTopBtn" variant="outlined" color="primary" onClick={scrollTop}>
          Zum Seitenanfang
        </Button>
        <Button
          variant="contained"
          disabled={!hasMore}
          color="primary"
          onClick={onLoadMore}
          data-testid="loadMoreButton"
          className="load-more-articles"
        >
          {loadingMore ? <CircularProgress color={'inherit'} size="1.5em" /> : 'Mehr Artikel Anzeigen'}
        </Button>
      </footer>
      <ConfirmationDialog
        handleClose={onDenyActionApply}
        handleConfirm={onConfirmActionApply}
        open={showConfirmActionDialog}
        title={selectedAction ? CONFIRM_ACTION_DIALOG_TEXT[selectedAction].title : ''}
        text={selectedAction ? CONFIRM_ACTION_DIALOG_TEXT[selectedAction].text(checkedArticleIds.length) : ''}
        actionLabel={selectedAction ? CONFIRM_ACTION_DIALOG_TEXT[selectedAction].actionLabel : ''}
      />
      <ArticleActionDialog
        open={actionDialogData.loadingDialogIsOpen}
        actionDialogData={actionDialogData}
        selectedAction={selectedAction || ''}
        handleConfirm={handleActionFinishedConfirm}
      />
    </article>
  );
};

export default ArticleJobList;
