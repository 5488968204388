import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BildLogo } from '../../assets/Logos/Brands/Bild';
import { WeltLogo } from '../../assets/Logos/Brands/Welt';
import { BRAND } from '../../models/Article';
import { useAuthContext } from '../../services/Auth/AuthContext';
import { useArticlesContext } from '../ArticleLandingPage/store/ArticlesContext';
import './BrandChooser.scss';

const BrandChooser = () => {
  const { user, brand, switchBrand } = useAuthContext();
  const { resetArticlesFilter } = useArticlesContext();
  const history = useHistory();

  useEffect(() => {
    if (brand) {
      history.push(`/${brand}`);
    }
  }, [brand]);

  const handleBrandChoice = (selectedBrand: BRAND) => {
    resetArticlesFilter();
    switchBrand(selectedBrand);
  };

  return (
    <div className="brandChooser" data-testid="brandChooser-page">
      <div className="brandChooser--header">
        <h1>Hallo {user.name}</h1>
        <h2>Für welche Marke möchtest Du Artikel vertonen?</h2>
      </div>
      <div className="brandChooser--grid">
        <div
          className="logoWrapper logoWrapper__welt"
          data-testid="brandChooser-weltLogo"
          onClick={() => handleBrandChoice(BRAND.WELT)}
        >
          <WeltLogo />
        </div>

        <div
          className="logoWrapper logoWrapper__bild"
          data-testid="brandChooser-bildLogo"
          onClick={() => handleBrandChoice(BRAND.BILD)}
        >
          <BildLogo />
        </div>
      </div>
    </div>
  );
};

export default BrandChooser;
