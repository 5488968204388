export enum NlpEnrichmentProgress {
  RUNNING = 'RUNNING',
  NOT_RUN = 'NOT_RUN',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}
export enum SynthesisProgress {
  RUNNING = 'RUNNING',
  NOT_RUN = 'NOT_RUN',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  SYNTHESIS_TOO_LONG_WONT_SYNTH = 'SYNTHESIS_TOO_LONG_WONT_SYNTH',
}
export enum PublicationProgress {
  RUNNING = 'RUNNING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  USER_DEPUBLISHED = 'USER_DEPUBLISHED',
  CMS_DEPUBLISHED = 'CMS_DEPUBLISHED',
  DEPUBLISH_FAILURE = 'DEPUBLISH_FAILURE',
}

export interface INlpEnrichment {
  progress: NlpEnrichmentProgress;
  finishedAt?: number;
}

export interface ISynthesis {
  ordinal?: number;
  s3ObjectKey?: string;
  progress: SynthesisProgress;
  createdAt?: number;
  startedAt?: number;
  finishedAt?: number;
  sentencesStartTimeInSec?: Array<number>;
}

export enum PublicationType {
  MANUAL = 'MANUAL',
  USER_REPUBLICATION = 'USER_REPUBLICATION',
  CMS_REPUBLICATION = 'CMS_REPUBLICATION',
  AUTO_PUBLISHED = 'AUTO_PUBLISHED',
}

export interface IPublication {
  progress: PublicationProgress;
  podcastId: number;
  startedAt?: number;
  publicationType?: PublicationType;
  synthesis?: ISynthesis;
  finishedAt?: number;
}

export interface ITtsModel {
  displayName: string;
  modelId: string;
  enabled: boolean;
}

export enum ArticleStatus {
  NOT_RUN = 'NOT_RUN',
  RUNNING = 'RUNNING',
  SYNTHESIZED = 'SYNTHESIZED',
  FAILED = 'FAILED',
  PUBLISHING = 'PUBLISHING',
  PREMIUM = 'PREMIUM',
  AUTO_PUBLISHED = 'AUTO_PUBLISHED',
  UNKNOWN = 'UNKNOWN',
  CHECKED = 'CHECKED',
  PRE_CHECKED = 'PRE_CHECKED',
  DEPUBLISHED = 'DEPUBLISHED',
}

export const ArticleStatusToView = {
  [ArticleStatus.UNKNOWN]: 'Unbekannt',
  [ArticleStatus.RUNNING]: 'In Bearbeitung',
  // If the synthesis doesn't run, BE sends "NOT_RUN" status. But in the FE we are showing "Running" status to the users
  [ArticleStatus.NOT_RUN]: 'In Bearbeitung',
  [ArticleStatus.SYNTHESIZED]: 'Synthetisiert',
  [ArticleStatus.FAILED]: 'Fehlgeschlagen',
  [ArticleStatus.PUBLISHING]: 'Wird veröffentlicht',
  [ArticleStatus.CHECKED]: 'Geprüft',
  [ArticleStatus.PRE_CHECKED]: 'Geprüft',
  [ArticleStatus.PREMIUM]: 'Premium',
  [ArticleStatus.AUTO_PUBLISHED]: 'Auto-veröffentlicht',
  [ArticleStatus.DEPUBLISHED]: 'Unveröffentlicht',
} as const;

export type ArticleStatusUIName = typeof ArticleStatusToView[keyof typeof ArticleStatusToView];

export enum BRAND {
  WELT = 'welt',
  BILD = 'bild',
}

export interface IArticle {
  articleSourceVersion: number;
  brand: BRAND;
  createdAt: number;
  categories: string[];
  downloadLink: string;
  externalId: string;
  id: string;
  isPremium: boolean;
  model?: ITtsModel;
  nlpEnrichment: INlpEnrichment;
  publications: IPublication[];
  publishedAt?: number;
  s3ObjectKey?: string;
  status: ArticleStatus;
  synthesis: ISynthesis;
  text: string;
  title: string;
  updatedAt: number;
}
