import ideasLogo from '../../assets/Logos/ideas_logo.png';
import './Footer.scss';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-wrapper">
          <a
            href="https://www.ideas-engineering.io"
            className="floating-image-wrapper"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ideasLogo} alt="Ideas Engineering" />
          </a>
          <p>
            built by Tailors and Team AI at
            <br />
            Ideas Engineering
          </p>
        </div>
      </footer>
    </>
  );
};
export default Footer;
