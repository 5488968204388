import { memo, ReactNode } from 'react';
import { useAuthContext } from 'src/services/Auth/AuthContext';
import Header from '../Header/Header';
import VerticalMenu from '../VerticalMenu/VerticalMenu';
import './Layout.scss';

const Layout = memo(({ children }: { children: ReactNode }) => {
  const { brand, switchBrand, user } = useAuthContext();

  return (
    <div data-testid="layout--wrapper" className="tts-page-wrapper">
      <Header />
      {brand && <VerticalMenu brand={brand} switchBrand={switchBrand} user={user} />}
      <main className="main-content">{children}</main>
    </div>
  );
});

export default Layout;
